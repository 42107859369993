import React, {
  createContext, useCallback, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';

import api from '../services/api';
import { authEmail } from '../Firebase/authLogin';

interface IAuthContext {
  signed: boolean;
  storeNew: boolean;
  store: boolean;
  handleNewStore(data: any): void;
  login(email: string, password: string): void;
  loginWithPhone(phone: string): void;
  logout(): void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC = ({ children }) => {
  const [signed, setSigned] = useState<boolean>(() => {
    const token = localStorage.getItem('@off-let:signed');

    if (token) {
      return true;
    }
    return false;
  });
  const [storeNew, setStoreNew] = useState<boolean>(() => {
    const token = localStorage.getItem('@off-let:storenew');
    if (token) {
      return true;
    }
    return false;
  });
  const [store, setStore] = useState<boolean>(() => {
    const token = localStorage.getItem('@off-let:store');
    if (token) {
      return true;
    }
    return false;
  });

  const login = useCallback(async (email: string, password: string) => {
    const response = await authEmail(email, password);
    if (response.user.email === email) {
      localStorage.setItem('@off-let:signed', response.user.email);
      setSigned(true);
    }
  }, []);
  const handleNewStore = (data: any) => {
    localStorage.setItem('@off-let:store', JSON.stringify(data));
    setStoreNew(false);
    setStore(true);
    setSigned(true);
  };
  const loginWithPhone = useCallback(async (phone: string) => {
    const data = {
      phone,
    };
    const verifyStoreExist = await api.post('/stores/phone', data).catch((error) => {
      if (error)alert(error.response.data?.validation.body.message);
    }).then((result: any) => {
      return result;
    });

    if (verifyStoreExist?.data?.phone === phone) {
      setSigned(true);

      localStorage.setItem('@off-let:signed', phone);
      localStorage.setItem('@off-let:store', JSON.stringify(verifyStoreExist.data));
      setStore(true);
    } else {
      localStorage.setItem('@off-let:signed', phone);
      setStoreNew(true);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('@off-let:signed');
    localStorage.removeItem('@off-let:storenew');
    localStorage.removeItem('@off-let:store');
    localStorage.removeItem('@off-let:apikey');
    setSigned(false);
    window.location.href = '/login';
  };

  return (
    <AuthContext.Provider value={{
      signed, storeNew, store, login, logout, loginWithPhone, handleNewStore,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider, useAuth };
