import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';

import api from '../../services/api';
import Button from '../../components/Button';
import Sidebar from '../../components/sidebar';
import Topbar from '../../components/Topbar';
import SearchLocationInput from '../../components/SearchInput';
import { useCoord } from '../../hooks/coordinates';
import { uploadImageToStorage } from '../../Firebase/uploadImage';
import {
  Container,
  Page,
  Title,
  EditStore,
  DropContainer,
  UploadMessage,
  Logo,
} from './styles';

interface Coordinates {
  longitude: number;
  latitude: number;
}
interface Address {
  street: string;
  street_number: string;
  zipcode: string;
  state: string;
  city: string;
  country: string;
  neighborhood?: string;
  complementary?: string;
}

interface IMall {
  _id: string;
  name: string;
  generic_name: string;
  logo?: string;
  address: Address;
  location: {
    coordinates: [number, number];
  };
  coordinates: Coordinates;
}

declare const window: any;

export const SettingsPage: React.FC = () => {
  const [editComponent, setEditComponent] = useState<boolean>(false);
  const [commercial_name, setCommercial_name] = useState<string>('');
  const [corporate_name, setCorporate_name] = useState<string>('');
  const [cnpj, setCnpj] = useState<string>('');
  const [imageLogo, setImageLogo] = useState<string>('');
  const [file, setFile] = useState<any>('');
  const [streetNumber, setStreetNumber] = useState<any>('');
  const [route, setRoute] = useState<any>('');
  const [neighborhood, setNeighborhood] = useState<any>('');
  const [filesPreview, setFilesPreview] = useState<any>();
  const [city, setCity] = useState<string>('');
  const [complementary, setComplementary] = useState<any>();
  const [state, setState] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [postalCode, setPostalCode] = useState<any>('');
  const [id, setId] = useState<string>('');

  const { coordenates } = useCoord();

  const renderDragMessage = (isDragActive: any, isDragReject: any) => {
    if (!isDragActive) {
      return (
        <UploadMessage>Arraste ou click para atualizar a logo</UploadMessage>
      );
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado!</UploadMessage>;
    }
    return <UploadMessage type="success">Solte aqui ... </UploadMessage>;
  };

  const addressComplete = (addss: any) => {
    addss?.map((addrss: any) => {
      switch (addrss.types[0]) {
        case 'street_number':
          setStreetNumber(addrss.long_name);
          break;
        case 'route':
          setRoute(addrss.long_name);
          break;
        case 'political':
          setNeighborhood(addrss.long_name);
          break;
        case 'sublocality_level_1':
          setNeighborhood(addrss.long_name);
          break;
        case 'administrative_area_level_2':
          setCity(addrss.long_name);
          break;
        case 'administrative_area_level_1':
          setState(addrss.long_name);
          break;
        case 'country':
          setCountry(addrss.long_name);
          break;
        case 'postal_code':
          setPostalCode(addrss.long_name);
          break;

        default:
          break;
      }
    });
  };

  useEffect(() => {
    async function loadStores() {
      const store = JSON.parse(localStorage.getItem('@off-let:store') || '');
      setId(store?._id);
      setStreetNumber(store.address.street_number);
      setPostalCode(store.address.zipcode);
      setCountry(store.address.country);
      setNeighborhood(store.address.neighborhood);
      setComplementary(store.address.complementary);
      setRoute(store.address.street);
      setCnpj(store.cnpj);
      setCommercial_name(store?.commercial_name);
      setCorporate_name(store?.corporate_name);
      setImageLogo(store?.logo);
      setState(store.address.state);
      setCity(store.address.city);
    }
    async function anyNameFunction2() {
      if (coordenates) {
        addressComplete(coordenates.address_components);
      }
    }

    if (coordenates) {
      anyNameFunction2();
    } else {
      loadStores();
    }
  }, [coordenates]);

  const handleUpload = async (files: any) => {
    setFile(files);
    const preview = URL.createObjectURL(files?.[0]);
    setFilesPreview(preview);
  };

  const handleSubmitUpdate = async (): Promise<void> => {
    let avatar: any;
    if (file) {
      const avatarT = await uploadImageToStorage(file, cnpj);
      avatar = avatarT;
    }
    const data = {
      commercial_name,
      corporate_name,
      logo: avatar,
      address: {
        street: route,
        street_number: streetNumber,
        zipcode: postalCode,
        state,
        city,
        country,
        neighborhood,
        complementary,
      },
    };
    const response = await api
      .put(`/stores/${id}`, data)
      .catch((error) => {
        if (error.response.data.validation) {
          alert(error.response.data.validation.body.message);
        } else if (error.response.data.message)
          alert(error.response.data.message);
      })
      .then((result: any) => {
        return result;
      });
    if (response?.data) {
      localStorage.setItem('@off-let:store', JSON.stringify(response.data));
      window.location.reload();
    }
    // localStorage.removeItem('@off-let:store');
  };
  const handleEdit = () => {
    setEditComponent(true);
  };

  return (
    <>
      <Container className="wrapper">
        <Sidebar />
        <Topbar />
        <Page className="content">
          <div className="page-heading">
            <Title className="page-title">Configuracoes da Loja</Title>
          </div>
          {!editComponent && (
            <EditStore className="panel">
              <div className="row">
                <div className="col-xs-12 col-md-3">
                  <div className="logo-container">
                    <Logo src={imageLogo} />
                  </div>
                </div>
                <div className="col-xs-12 col-md-9">
                  <form>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input
                          name="name"
                          defaultValue={commercial_name}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="corporate_name"
                          defaultValue={corporate_name}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="cnpj"
                          defaultValue={cnpj}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3 form-group">
                        <input
                          name="country"
                          defaultValue={country}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3 form-group">
                        <input
                          name="state"
                          defaultValue={state}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="zipcode"
                          defaultValue={postalCode}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="street"
                          defaultValue={route}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="street_number"
                          defaultValue={streetNumber}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="city"
                          defaultValue={city}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="neighborhood"
                          defaultValue={neighborhood}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="complementary"
                          placeholder="Complemento (opcional)"
                          defaultValue={complementary}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                  </form>
                  <Button onClick={handleEdit} className="btn btn-success">
                    Editar
                  </Button>
                </div>
              </div>
            </EditStore>
          )}
          {editComponent && (
            <EditStore className="panel">
              <div className="row">
                <div className="col-xs-12 col-md-3">
                  <div className="form-group">
                    <label>Logo:</label>
                    <Dropzone accept="image/*" onDropAccepted={handleUpload}>
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragReject,
                      }) => (
                        <DropContainer
                          {...getRootProps()}
                          isDragActive={isDragActive} // aceitar img
                          isDragReject={isDragReject}
                          img={filesPreview}
                        >
                          <input {...getInputProps()} />
                          {renderDragMessage(isDragActive, isDragReject)}
                        </DropContainer>
                      )}
                    </Dropzone>
                  </div>
                </div>
                <div className="col-xs-12 col-md-9">
                  <form>
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="form-group">
                          <SearchLocationInput name="any" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input
                          name="name"
                          placeholder="Nome"
                          onChange={(event: any) =>
                            setCommercial_name(event.target.value)
                          }
                          value={commercial_name}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="generic_name"
                          placeholder="Razao Social"
                          onChange={(event: any) =>
                            setCorporate_name(event.target.value)
                          }
                          value={corporate_name}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="zipcode"
                          placeholder={postalCode}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3 form-group">
                        <input
                          name="country"
                          placeholder={country}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3 form-group">
                        <input
                          name="state"
                          placeholder={state}
                          disabled
                          className="form-control"
                        />
                      </div>

                      <div className="col-md-6 form-group">
                        <input
                          name="street"
                          placeholder={route}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="street_number"
                          placeholder={streetNumber}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="city"
                          placeholder={city}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="neighborhood"
                          placeholder={neighborhood}
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          name="complementary"
                          placeholder="Complemento (opcional)"
                          onChange={(event: any) =>
                            setComplementary(event.target.value)
                          }
                          value={complementary}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </form>
                  <Button
                    onClick={handleSubmitUpdate}
                    className="btn btn-success"
                  >
                    Atualizar Informacoes
                  </Button>
                </div>
              </div>
            </EditStore>
          )}
        </Page>
      </Container>
    </>
  );
};

export default SettingsPage;
