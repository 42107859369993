import { auth } from '../config/firebase.js';

async function authEmail(email: string, password: string): Promise<any> {
  const response = await auth.signInWithEmailAndPassword(email, password).catch((error) => {
    const errorMessage = error.message;
    console.log(errorMessage);
    // ...
  });
  return response;
}

export { authEmail };
