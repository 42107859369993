import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 5px;
  background-color: #172552;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Img = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 33%;
`;

export const Painel = styled.div`
  width: 40%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 4%;
  padding-bottom: 4%;
  padding-top: 4%;
  justify-content: center;
  input {
    border: 1px solid #000;
    height: 54%;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 17px;
    border-radius: 7px;
  }
`;
export const Button = styled.button`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  background-color: #e83c4d;
  height: 33px;
  border-radius: 10px;
  border: 0;
  margin-top: 47px;
  color: #f4ede8;
  font-weight: 500;
  transition: background-color 0.2s;
  &:hover {
    background: ${shade(0.2, '#BCF9C5')};
  }
`;
export const Cap = styled.div`
  position: absolute;
`;
