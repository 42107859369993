import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;

  @media (max-width: 800px) {
    justify-content: space-between;
  }

  .menu-wrapper {
    width: 32px;
    height: 18px;
    cursor: pointer;
  }

  .hamburger-menu,
  .hamburger-menu:after,
  .hamburger-menu:before {
    width: 32px;
    height: 2px;
  }

  .hamburger-menu {
    display: none;
    position: relative;
    transform: translateY(8px);
    background: rgba(255, 255, 255, 1);
    transition: all 0ms 300ms;

    @media (max-width: 800px) {
      display: block;
    }

    &.animate {
      background: rgba(255, 255, 255, 0);
    }
  }

  .hamburger-menu:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 8px;
    background: rgba(255, 255, 255, 1);
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .hamburger-menu:after {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    background: rgba(255, 255, 255, 1);
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .hamburger-menu.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .hamburger-menu.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .logo-topbar {
    display: none;
    @media (max-width: 800px) {
      display: block;
      width: 64px;
    }
  }

  .logout-button {
    background-color: #fff;
    border: 0;
    color: var(--primary400);
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    svg {
      margin-right: 8px;
    }
    &:hover {
      background-color: var(--primary100);
    }
    @media (max-width: 800px) {
      color: var(--primary300);
      background-color: transparent;
    }
  }
`;
