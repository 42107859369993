import styled from 'styled-components';

interface Props {
  isDragActive?: any;
  dragActive?: any;
  dragReject?: any;
  isDragReject?: any;
  img?: any;
}
interface Type {
  type?: 'default' | 'error' | 'success';
}

export const Container = styled.div`
  background-color: #212e63;
  height: 100vh;

  .logo-header {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: auto;
      height: 88px;
    }
  }
`;
export const Page = styled.div`
  background-color: #212e63;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
`;
export const Title = styled.h3`
  color: #fff;
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  padding: 48px;
  text-align: center;
  span {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: var(--accent500);
  }
`;

export const EditStore = styled.div``;

export const MapStyle = styled.div``;

export const Logo = styled.img``;

export const DropContainer = styled.div<Props>`
  height: 160px;
  display: flex;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-color: #f8f8fa;
  border: 1px solid #f0f1f5;
  box-sizing: border-box;
  border-radius: 8px;
  ${(props) => props.isDragActive && props.dragActive};
  ${(props) => props.isDragReject && props.dragReject};
  text-align: center;
  color: var(--primary500);
  cursor: pointer;
  &:hover {
    background-color: #f0f1f5;
  }
`;
const messageResult = {
  default: '#212E63',
  error: '#FC4F4F',
  success: '#14C87C',
};
export const UploadMessage = styled.p<Type>`
  display: flex;
  color: ${(props) => messageResult[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: 27px;
`;
export const Btn1 = styled.button`
  display: flex;
  justify-content: center;
  position: absolute;
  min-width: 9%;
  top: 0;
  left: 0;
  align-items: center;
  margin: 2%;
  padding: 7px;
  border: 1px solid #dddd;
  border-radius: 7px;
  color: #ffffff;
  background-color: transparent;
  h2 {
    font-size: 12px;
    margin-left: 4%;

  }
`;
export const ButtonVisit = styled.button`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 12%;
  align-items: center;
  justify-content: center;
  margin: 2%;
  padding: 7px;
  border: 1px solid #dddd;
  border-radius: 7px;
  color: #ffffff;
  background-color: transparent;
  img {
    height: 21px;
    width: 29px;
  }
  h3 {
    font-size: 12px;
    margin-left: 4%;
  }
`;
export const Button2 = styled.button`
  background-color: transparent;
  border: none;
`;

export const SuccessContainer = styled.div`
  background-color: #212e63;
  height: 100vh;
  padding: 20%;
  align-items: center;
  justify-content: center;

  .logo-header {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: auto;
      height: 88px;
    }
  }
`;
export const SuccessText = styled.div`
  color: #fff;
  font-size: 30px;
  line-height: 32px;
  font-weight: 500;
  padding: 48px;
  text-align: center;
`;
