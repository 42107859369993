import React, { useEffect } from 'react';

import Sidebar from '../../components/sidebar';
import Topbar from '../../components/Topbar';
import {
  Container,
  Page,
  Title,
  ListDocumentation,
  RepositoriesInfo,
} from './styles';

const Documentation: React.FC = () => {
  useEffect(() => {
    async function loadData() {
      // const response = await api.get('/Documentation');

    }
    loadData();
  }, []);

  return (
    <>
      <Container className="wrapper">
        <Sidebar />
        <Topbar />
        <Page className="content">
          <div className="row page-heading">
            <div className="col-xs-12 col-md-8">
              <Title className="page-title">Documentation</Title>
            </div>

            <div className="col-xs-12 col-md-4" />
          </div>

          <ListDocumentation className="panel">
            <div style={{
              display: 'flex',
              padding: '7%',
            }}
            >
              <p>
                <span style={{ fontSize: 20, fontWeight: 700 }}>INTRODUCTION</span>
                <br />
                <br />
                <span style={{ fontSize: 15, fontWeight: 700 }}>Find below the Off-Let's API documentation</span>
                <br />
                <span style={{ fontSize: 15, fontWeight: 700 }}>Test and production environment</span>
                <br />
                <br />
                <br />
                <span style={{ fontSize: 18, fontWeight: 700 }}>Production</span>
                <br />
                <br />
                <span style={{ fontSize: 17, fontWeight: 700, color: '#0c547b' }}>https://api.off-let.com/</span>
                <br />
                <br />
                <span style={{ fontSize: 18, fontWeight: 700 }}>Test</span>
                <br />
                <br />
                <span style={{ fontSize: 17, fontWeight: 700, color: '#0c547b' }}>https://homologation.off-let.com/</span>
                <br />
                <br />
                <br />
                <br />
                <span style={{ fontSize: 18, fontWeight: 700 }}>Auth</span>
                <br />
                <br />
                <span style={{ fontSize: 15, fontWeight: 700, color: '#242728' }}>
                  You must provide in every request header your private api key
                  <br />
                  if you want to get your private api key, access your API Dashboard
                </span>
              </p>
            </div>
            {/*
            * Create an Product
            */}
            <RepositoriesInfo>
              <div>
                <div>
                  <span style={{ color: '#fff', fontSize: 22 }}>Create Product</span>
                  <br />
                  <br />
                  <div>
                    <span style={{ color: '#24b814' }}>POST</span>
                    <span style={{ color: '#fff' }}> /products</span>
                    <br />
                    <br />
                    <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                    <br />
                  </div>
                </div>

                <div>
                  <br />
                  <br />

                  <span style={{ color: '#fff' }}>
                    HEADERS:

                    <br />
                    <br />
                    &#160;&#32;Content-Type: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>application/json</span>
                    ,
                    <br />
                    <br />
                    &#160;&#32;Authorization: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>
                      Bearer
                      {' '}
                      <span style={{ color: '#197ecb' }}>TOKEN</span>
                    </span>
                    ,
                    <br />
                  </span>
                </div>

                <div style={{ color: '#fff', backgroundColor: '#222121', padding: 22 }}>
                  <pre>
                    <span style={{ color: '#e0cece', fontSize: 15 }}>
                      BODY
                      <br />
                      <br />
                    </span>
                    <code>
                      <span>
                        &#123;
                        <br />
                        &#160;&#32;countryIso: &rdquo;
                        <span style={{ color: '#b8ad14' }}>Country identified ISO code</span>
                        &rdquo;,
                        <br />

                        &#160;&#32;title: &rdquo;
                        <span style={{ color: '#b8ad14' }}>String</span>
                        &rdquo;,
                        <br />
                        &#160;&#32;description:
                        {' '}
                        <span style={{ color: '#b8ad14' }}>text</span>
                        ,
                        <br />
                        &#160;&#32;modelReference: &rdquo;
                        <span style={{ color: '#b8ad14' }}>String</span>
                        &rdquo;,
                        <br />
                        &#160;&#32;sectionId:
                        <span style={{ color: '#b8ad14' }}> &#32;section id</span>
                        ,
                        <br />
                        &#160;&#32;&rdquo;images&rdquo;:&#32;&#32;
                        [
                        <br />
                        &#160;&#160;&#160;&#123;
                        <br />

                        &#160;&#160;&#160;&#160;url:
                        {' '}
                        <span style={{ color: '#b8ad14' }}>String</span>
                        ,
                        <br />
                        &#160;&#160;&#160;&#160;displayPriority:
                        {' '}
                        <span style={{ color: '#2389d8' }}>Number</span>
                        <br />
                        &#160;&#160;&#160;&#125;,
                        <br />
                        &#160;&#160;]
                        <br />
                        &#125;

                      </span>
                    </code>
                  </pre>
                </div>

                <div>
                  <span style={{ color: '#fff' }}>Response</span>
                  <br />
                  <br />
                  <span style={{ color: '#116e07' }}>200</span>
                  <br />
                  <br />

                  <div style={{ color: '#fff', backgroundColor: '#222121', padding: 22 }}>
                    <pre>

                      <code>
                        <span>
                          &#123;
                          <br />
                          &#160;&#32;id: &rdquo;
                          <span style={{ color: '#b8ad14' }}>3053af91-ba2b-450d-ba00-9516d8b533ac</span>
                          &rdquo;,
                          <br />
                          &#160;&#32;countryIso: &rdquo;
                          <span style={{ color: '#b8ad14' }}>Country identified ISO code</span>
                          &rdquo;,
                          <br />
                          &#160;&#32;title: &rdquo;
                          <span style={{ color: '#b8ad14' }}>String</span>
                          &rdquo;,
                          <br />
                          &#160;&#32;description:
                          {' '}
                          <span style={{ color: '#b8ad14' }}>text</span>
                          ,
                          <br />
                          &#160;&#32;modelReference: &rdquo;
                          <span style={{ color: '#b8ad14' }}>String</span>
                          &rdquo;,
                          <br />
                          &#160;&#32;sectionId:
                          <span style={{ color: '#b8ad14' }}> &#32;section id</span>
                          ,
                          <br />
                          &#160;&#32;&rdquo;images&rdquo;:&#32;&#32;
                          [
                          <br />
                          &#160;&#160;&#160;&#123;
                          <br />
                          &#160;&#160;&#160;&#160;id:
                          {' '}
                          <span style={{ color: '#b8ad14' }}>&rdquo;ee466ba1-d59d-4d26-b2dc-a84d1cf28f15&rdquo;</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;url:
                          {' '}
                          <span style={{ color: '#b8ad14' }}>String</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;displayPriority:
                          {' '}
                          <span style={{ color: '#2389d8' }}>Number</span>
                          <br />
                          &#160;&#160;&#160;&#125;,
                          <br />
                          &#160;&#160;]
                          <br />
                          &#125;

                        </span>
                      </code>
                    </pre>
                  </div>
                  <br />
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />

                </div>
              </div>
            </RepositoriesInfo>
            <br />
            <br />

            {/*
            * Update an Product
            */}
            <RepositoriesInfo>
              <div>
                <div>
                  <span style={{ color: '#fff', fontSize: 22 }}>Update Product</span>
                  <br />
                  <br />
                  <div>
                    <span style={{ color: '#e48512' }}>PUT</span>
                    <span style={{ color: '#fff' }}> /products/:product_id</span>
                    <br />
                    <br />
                    <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                    <br />
                  </div>
                </div>

                <div>
                  <br />
                  <br />

                  <span style={{ color: '#fff' }}>
                    HEADERS:

                    <br />
                    <br />
                    &#160;&#32;Content-Type: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>application/json</span>
                    ,
                    <br />
                    <br />
                    &#160;&#32;Authorization: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>
                      Bearer
                      {' '}
                      <span style={{ color: '#197ecb' }}>TOKEN</span>
                    </span>
                    ,
                    <br />
                  </span>
                </div>

                <div style={{ color: '#fff', backgroundColor: '#222121', padding: 22 }}>
                  <pre>
                    <span style={{ color: '#e0cece', fontSize: 15 }}>
                      BODY
                      <br />
                      <br />
                    </span>
                    <code>
                      <span>
                        &#123;
                        <br />
                        &#160;&#32;countryIso (optional): &rdquo;
                        <span style={{ color: '#b8ad14' }}>Country identified ISO code</span>
                        &rdquo;,
                        <br />
                        &#160;&#32;title (optional): &rdquo;
                        <span style={{ color: '#b8ad14' }}>String</span>
                        &rdquo;,
                        <br />
                        &#160;&#32;description (optional):
                        {' '}
                        <span style={{ color: '#b8ad14' }}>text</span>
                        ,
                        <br />
                        &#160;&#32;modelReference (optional): &rdquo;
                        <span style={{ color: '#b8ad14' }}>String</span>
                        &rdquo;,
                        <br />
                        &#160;&#32;sectionId (optional):
                        <span style={{ color: '#b8ad14' }}> &#32;section id</span>
                        ,
                        <br />
                        &#160;&#32;images (optional):&#32;&#32;
                        [
                        <br />
                        &#160;&#160;&#160;&#123;
                        <br />

                        &#160;&#160;&#160;&#160;url:
                        {' '}
                        <span style={{ color: '#b8ad14' }}>String</span>
                        ,
                        <br />
                        &#160;&#160;&#160;&#160;displayPriority:
                        {' '}
                        <span style={{ color: '#2389d8' }}>Number</span>
                        <br />
                        &#160;&#160;&#160;&#125;,
                        <br />
                        &#160;&#160;]
                        <br />
                        &#125;

                      </span>
                    </code>
                  </pre>
                </div>

                <div>
                  <span style={{ color: '#fff' }}>Response</span>
                  <br />
                  <br />
                  <span style={{ color: '#116e07' }}>200</span>
                  <br />
                  <br />

                  <div style={{ color: '#fff', backgroundColor: '#222121', padding: 22 }}>
                    <pre>

                      <code>
                        <span>
                          &#123;
                          <br />
                          &#160;&#32;id: &rdquo;
                          <span style={{ color: '#b8ad14' }}>ee466ba1-d59d-4d26-b2dc-a84d1cf28f15</span>
                          &rdquo;,
                          <br />
                          &#160;&#32;countryIso: &rdquo;
                          <span style={{ color: '#b8ad14' }}>Country identified ISO code</span>
                          &rdquo;,
                          <br />
                          &#160;&#32;title: &rdquo;
                          <span style={{ color: '#b8ad14' }}>String</span>
                          &rdquo;,
                          <br />
                          &#160;&#32;description:
                          {' '}
                          <span style={{ color: '#b8ad14' }}>text</span>
                          ,
                          <br />
                          &#160;&#32;modelReference: &rdquo;
                          <span style={{ color: '#b8ad14' }}>String</span>
                          &rdquo;,
                          <br />
                          &#160;&#32;sectionId:
                          <span style={{ color: '#b8ad14' }}> &#32;section id</span>
                          ,
                          <br />
                          &#160;&#32;&rdquo;images&rdquo;:&#32;&#32;
                          [
                          <br />
                          &#160;&#160;&#160;&#123;
                          <br />
                          &#160;&#160;&#160;&#160;id:
                          {' '}
                          <span style={{ color: '#b8ad14' }}>&rdquo;ee466ba1-d59d-4d26-b2dc-a84d1cf28f15&rdquo;</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;url:
                          {' '}
                          <span style={{ color: '#b8ad14' }}>String</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;displayPriority:
                          {' '}
                          <span style={{ color: '#2389d8' }}>Number</span>
                          <br />
                          &#160;&#160;&#160;&#125;,
                          <br />
                          &#160;&#160;]
                          <br />
                          &#125;

                        </span>
                      </code>
                    </pre>
                  </div>
                  <br />
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />

                </div>
              </div>
            </RepositoriesInfo>
            <br />
            <br />
            {/*
            * List Products
            */}
            <RepositoriesInfo>
              <div>
                <div>
                  <span style={{ color: '#fff', fontSize: 22 }}>List Products</span>
                  <br />
                  <br />
                  <div>
                    <span style={{ color: '#ae67eddd' }}>GET</span>
                    <span style={{ color: '#fff' }}> /products</span>
                    <br />
                    <br />
                    <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                    <br />
                  </div>
                </div>

                <div>
                  <br />
                  <br />

                  <span style={{ color: '#fff' }}>
                    HEADERS:

                    <br />
                    <br />
                    &#160;&#32;Content-Type: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>application/json</span>
                    ,
                    <br />
                    <br />
                    &#160;&#32;Authorization: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>
                      Bearer
                      {' '}
                      <span style={{ color: '#197ecb' }}>TOKEN</span>
                    </span>
                    ,
                    <br />
                  </span>
                </div>

                <br />
                <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                <br />

                <div>
                  <span style={{ color: '#fff' }}>Response</span>
                  <br />
                  <br />
                  <span style={{ color: '#116e07' }}>200</span>
                  <br />
                  <br />

                  <div style={{ color: '#fff', backgroundColor: '#222121', padding: 22 }}>
                    <pre>

                      <code>
                        <span>
                          &#123;
                          <br />
                          &#160;&#32;moreData:&#32;
                          <span style={{ color: '#b8ad14' }}> Boolean</span>
                          ,
                          <br />
                          &#160;&#32;page:&#32;
                          <span style={{ color: '#2389d8' }}> Number</span>
                          ,
                          <br />
                          &#160;&#32;qtyPerPage:&#32;
                          <span style={{ color: '#2389d8' }}> Number</span>
                          ,
                          <br />
                          &#160;&#32;data:&#32;
                          [
                          <br />
                          &#160;&#160;&#160;&#123;
                          <br />
                          &#160;&#160;&#160;&#160;&#32;id: &rdquo;
                          <span style={{ color: '#b8ad14' }}>ee466ba1-d59d-4d26-b2dc-a84d1cf28f15</span>
                          &rdquo;,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;countryIso: &rdquo;
                          <span style={{ color: '#b8ad14' }}>Country identified ISO code</span>
                          &rdquo;,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;title: &rdquo;
                          <span style={{ color: '#b8ad14' }}>String</span>
                          &rdquo;,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;description:
                          {' '}
                          <span style={{ color: '#b8ad14' }}>text</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;modelReference: &rdquo;
                          <span style={{ color: '#b8ad14' }}>String</span>
                          &rdquo;,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;sectionId:
                          <span style={{ color: '#b8ad14' }}> &#32;section id</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;&rdquo;images&rdquo;:&#32;&#32;
                          [
                          <br />
                          &#160;&#160; &#160;&#160;&#160;&#160;&#123;
                          <br />
                          &#160;&#160; &#160;&#160;&#160;&#160;&#160;id:
                          {' '}
                          <span style={{ color: '#b8ad14' }}>&rdquo;ee466ba1-d59d-4d26-b2dc-a84d1cf28f15&rdquo;</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;&#160;&#160;&#160;url:
                          {' '}
                          <span style={{ color: '#b8ad14' }}>String</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;&#160;&#160;&#160;displayPriority:
                          {' '}
                          <span style={{ color: '#2389d8' }}>Number</span>
                          <br />
                          &#160;&#160;&#160;&#160;&#160;&#160;&#125;,
                          <br />
                          &#160;&#160;&#160;&#160;&#160;]
                          <br />
                          &#160;&#160;&#160;&#160;&#125;
                          <br />
                          &#160;&#160;]
                          <br />
                          &#125;

                        </span>
                      </code>
                    </pre>
                  </div>
                  <br />
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />

                </div>
              </div>
            </RepositoriesInfo>
            <br />
            <br />
            {/*
            * Delete an Product
            */}
            <RepositoriesInfo>
              <div>
                <div>
                  <span style={{ color: '#fff', fontSize: 22 }}>Delete Product</span>
                  <br />
                  <br />
                  <div>
                    <span style={{ color: '#aa0909' }}>DELETE</span>
                    <span style={{ color: '#fff' }}> /products/:product_id</span>
                    <br />
                    <br />
                    <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                    <br />
                  </div>
                </div>

                <div>
                  <br />
                  <br />

                  <span style={{ color: '#fff' }}>
                    HEADERS:

                    <br />
                    <br />
                    &#160;&#32;Content-Type: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>application/json</span>
                    ,
                    <br />
                    <br />
                    &#160;&#32;Authorization: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>
                      Bearer
                      {' '}
                      <span style={{ color: '#197ecb' }}>TOKEN</span>
                    </span>
                    ,
                    <br />
                  </span>
                </div>

                <div>
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>Response</span>
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>Success: </span>
                  <span style={{ color: '#116e07' }}>200</span>
                  <br />
                  <br />
                  <span style={{ color: '#c45' }}>Error: </span>
                  <span style={{ color: '#cf9011' }}>400</span>
                  <pre>
                    <br />
                    <br />
                    <span style={{ color: '#e0cece', fontSize: 15 }}>
                      RESPONSE
                      <br />
                      <br />
                    </span>
                    <code>
                      <span style={{ color: '#fff' }}>
                        &#123;
                        <br />
                        &#160;&#32;
                        <span style={{ color: '#fff' }}>
                          error:

                        </span>
                        {' '}
                        <span style={{ color: '#b8ad14' }}>
                          &rdquo;
                          error message
                          &rdquo;

                        </span>
                        ,
                        <br />
                        &#125;

                      </span>
                    </code>
                  </pre>
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />

                </div>
              </div>
            </RepositoriesInfo>
            <br />
            <br />

            {/*
            * Create an Offer
            */}
            <RepositoriesInfo>
              <div>
                <div>
                  <span style={{ color: '#fff', fontSize: 22 }}>Create an Offer</span>
                  <br />
                  <br />
                  <div>
                    <span style={{ color: '#24b814' }}>POST</span>
                    <span style={{ color: '#fff' }}> /products/:product_id/offers</span>
                    <br />
                    <br />
                    <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                    <br />
                  </div>
                </div>

                <div>
                  <br />
                  <br />

                  <span style={{ color: '#fff' }}>
                    HEADERS:

                    <br />
                    <br />
                    &#160;&#32;Content-Type: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>application/json</span>
                    ,
                    <br />
                    <br />
                    &#160;&#32;Authorization: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>
                      Bearer
                      {' '}
                      <span style={{ color: '#197ecb' }}>TOKEN</span>
                    </span>
                    ,
                    <br />
                  </span>
                </div>

                <div style={{ color: '#fff', backgroundColor: '#222121', padding: 22 }}>
                  <pre>
                    <span style={{ color: '#e0cece', fontSize: 15 }}>
                      BODY
                      <br />
                      <br />
                    </span>
                    <code>
                      <span>
                        &#123;
                        <br />
                        &#160;&#32;advertiserLabel: &rdquo;
                        <span style={{ color: '#b8ad14' }}>mysite.com</span>
                        &rdquo;,
                        <br />
                        &#160;&#32;type: &rdquo;
                        <span style={{ color: '#b8ad14' }}>'AFFILIATE_LINK' | 'PROMOTIONAL' | 'EXTERNAL' | 'USER'</span>
                        &rdquo;,
                        <br />
                        &#160;&#32;url: &rdquo;
                        <span style={{ color: '#b8ad14' }}>https://product-link.example</span>
                        &rdquo;,
                        <br />
                        &#160;&#32;nicePrice:
                        {' '}
                        <span style={{ color: '#2389d8' }}>5199</span>
                        ,
                        <br />
                        &#160;&#32;regularPrice:
                        {' '}
                        <span style={{ color: '#2389d8' }}>9499.99</span>
                        ,
                        <br />
                        &#160;&#32;discount:
                        {' '}
                        <span style={{ color: '#2389d8' }}>45</span>
                        ,
                        <br />
                        &#125;

                      </span>
                    </code>
                  </pre>
                </div>

                <div>
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>Response</span>
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>Success: </span>
                  <span style={{ color: '#116e07' }}>200</span>
                  <br />
                  <br />
                  <span style={{ color: '#c45' }}>Error: </span>
                  <span style={{ color: '#cf9011' }}>400</span>
                  <pre>
                    <br />
                    <br />
                    <span style={{ color: '#e0cece', fontSize: 15 }}>
                      RESPONSE
                      <br />
                      <br />
                    </span>
                    <code>
                      <span style={{ color: '#fff' }}>
                        &#123;
                        <br />
                        &#160;&#32;
                        <span style={{ color: '#fff' }}>
                          error:

                        </span>
                        {' '}
                        <span style={{ color: '#b8ad14' }}>
                          &rdquo;
                          error message
                          &rdquo;

                        </span>
                        ,
                        <br />
                        &#125;

                      </span>
                    </code>
                  </pre>
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />

                </div>
              </div>
            </RepositoriesInfo>
            <br />
            <br />
            {/*
            * Update an Offer
            */}
            <RepositoriesInfo>
              <div>
                <div>
                  <span style={{ color: '#fff', fontSize: 22 }}>Update Offer</span>
                  <br />
                  <br />
                  <div>
                    <span style={{ color: '#e48512' }}>PUT</span>
                    <span style={{ color: '#fff' }}> /products/offers</span>
                    <br />
                    <br />
                    <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                    <br />
                  </div>
                </div>

                <div>
                  <br />
                  <br />

                  <span style={{ color: '#fff' }}>
                    HEADERS:

                    <br />
                    <br />
                    &#160;&#32;Content-Type: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>application/json</span>
                    ,
                    <br />
                    <br />
                    &#160;&#32;Authorization: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>
                      Bearer
                      {' '}
                      <span style={{ color: '#197ecb' }}>TOKEN</span>
                    </span>
                    ,
                    <br />
                  </span>
                </div>

                <div style={{ color: '#fff', backgroundColor: '#222121', padding: 22 }}>
                  <pre>
                    <span style={{ color: '#e0cece', fontSize: 15 }}>
                      BODY
                      <br />
                      <br />
                    </span>
                    <code>
                      <span>
                        &#123;
                        <br />
                        &#160;&#32;advertiserLabel (optional): &rdquo;
                        <span style={{ color: '#b8ad14' }}>mysite.com</span>
                        &rdquo;,
                        <br />
                        &#160;&#32;type (optional): &rdquo;
                        <span style={{ color: '#b8ad14' }}>'AFFILIATE_LINK' | 'PROMOTIONAL' | 'EXTERNAL' | 'USER'</span>
                        &rdquo;,
                        <br />
                        &#160;&#32;url (optional): &rdquo;
                        <span style={{ color: '#b8ad14' }}>https://product-link.example</span>
                        &rdquo;,
                        <br />
                        &#160;&#32;nicePrice (optional):
                        {' '}
                        <span style={{ color: '#2389d8' }}>5199</span>
                        ,
                        <br />
                        &#160;&#32;regularPrice (optional):
                        {' '}
                        <span style={{ color: '#2389d8' }}>9499.99</span>
                        ,
                        <br />
                        &#160;&#32;discount (optional):
                        {' '}
                        <span style={{ color: '#2389d8' }}>45</span>
                        ,
                        <br />
                        &#125;

                      </span>
                    </code>
                  </pre>
                </div>

                <div>
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>Response</span>
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>Success: </span>
                  <span style={{ color: '#116e07' }}>200</span>
                  <br />
                  <br />
                  <span style={{ color: '#c45' }}>Error: </span>
                  <span style={{ color: '#cf9011' }}>400</span>
                  <pre>
                    <br />
                    <br />
                    <span style={{ color: '#e0cece', fontSize: 15 }}>
                      RESPONSE
                      <br />
                      <br />
                    </span>
                    <code>
                      <span style={{ color: '#fff' }}>
                        &#123;
                        <br />
                        &#160;&#32;
                        <span style={{ color: '#fff' }}>
                          error:

                        </span>
                        {' '}
                        <span style={{ color: '#b8ad14' }}>
                          &rdquo;
                          error message
                          &rdquo;

                        </span>
                        ,
                        <br />
                        &#125;

                      </span>
                    </code>
                  </pre>
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />

                </div>
              </div>
            </RepositoriesInfo>
            <br />
            <br />
            {/*
            * Delete Offer
            */}
            <RepositoriesInfo>
              <div>
                <div>
                  <span style={{ color: '#fff', fontSize: 22 }}>Delete Offer</span>
                  <br />
                  <br />
                  <div>
                    <span style={{ color: '#aa0909' }}>DELETE</span>
                    <span style={{ color: '#fff' }}> /products/offers/:offer_id</span>
                    <br />
                    <br />
                    <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                    <br />
                  </div>
                </div>

                <div>
                  <br />
                  <br />

                  <span style={{ color: '#fff' }}>
                    HEADERS:

                    <br />
                    <br />
                    &#160;&#32;Content-Type: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>application/json</span>
                    ,
                    <br />
                    <br />
                    &#160;&#32;Authorization: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>
                      Bearer
                      {' '}
                      <span style={{ color: '#197ecb' }}>TOKEN</span>
                    </span>
                    ,
                    <br />
                  </span>
                </div>

                <div>
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>Response</span>
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>Success: </span>
                  <span style={{ color: '#116e07' }}>200</span>
                  <br />
                  <br />
                  <span style={{ color: '#c45' }}>Error: </span>
                  <span style={{ color: '#cf9011' }}>400</span>
                  <pre>
                    <br />
                    <br />
                    <span style={{ color: '#e0cece', fontSize: 15 }}>
                      RESPONSE
                      <br />
                      <br />
                    </span>
                    <code>
                      <span style={{ color: '#fff' }}>
                        &#123;
                        <br />
                        &#160;&#32;
                        <span style={{ color: '#fff' }}>
                          error:

                        </span>
                        {' '}
                        <span style={{ color: '#b8ad14' }}>
                          &rdquo;
                          error message
                          &rdquo;

                        </span>
                        ,
                        <br />
                        &#125;

                      </span>
                    </code>
                  </pre>
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />

                </div>
              </div>
            </RepositoriesInfo>
            <br />
            <br />
            {/*
            * List Offers
            */}
            <RepositoriesInfo>
              <div>
                <div>
                  <span style={{ color: '#fff', fontSize: 22 }}>List Offers</span>
                  <br />
                  <br />
                  <div>
                    <span style={{ color: '#ae67eddd' }}>GET</span>
                    <span style={{ color: '#fff' }}> /products/offers</span>
                    <br />
                    <br />
                    <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                    <br />
                  </div>
                </div>

                <div>
                  <br />
                  <br />

                  <span style={{ color: '#fff' }}>
                    HEADERS:

                    <br />
                    <br />
                    &#160;&#32;Content-Type: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>application/json</span>
                    ,
                    <br />
                    <br />
                    &#160;&#32;Authorization: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>
                      Bearer
                      {' '}
                      <span style={{ color: '#197ecb' }}>TOKEN</span>
                    </span>
                    ,
                    <br />
                  </span>
                </div>

                <br />
                <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                <br />

                <div>
                  <span style={{ color: '#fff' }}>Response</span>
                  <br />
                  <br />
                  <span style={{ color: '#116e07' }}>200</span>
                  <br />
                  <br />

                  <div style={{ color: '#fff', backgroundColor: '#222121', padding: 22 }}>
                    <pre>

                      <code>
                        <span>
                          &#123;
                          <br />
                          &#160;&#32;moreData:&#32;
                          <span style={{ color: '#b8ad14' }}> Boolean</span>
                          ,
                          <br />
                          &#160;&#32;data:&#32;
                          [
                          <br />
                          &#160;&#160;&#160;&#123;
                          <br />
                          &#160;&#160;&#160;&#160;&#32;id: &rdquo;
                          <span style={{ color: '#b8ad14' }}>ee466ba1-d59d-4d26-b2dc-a84d1cf28f15</span>
                          &rdquo;,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;category: &rdquo;
                          <span style={{ color: '#b8ad14' }}>Other</span>
                          &rdquo;,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;title: &rdquo;
                          <span style={{ color: '#b8ad14' }}>String</span>
                          &rdquo;,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;description:
                          {' '}
                          <span style={{ color: '#b8ad14' }}>text</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;current_price:
                          {' '}
                          <span style={{ color: '#2389d8' }}>5199</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;previous_price:
                          {' '}
                          <span style={{ color: '#2389d8' }}>9499.99</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;discount:
                          {' '}
                          <span style={{ color: '#2389d8' }}>45</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;previous_price_formatted:
                          <span style={{ color: '#b8ad14' }}> &#32;$9,499.99</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;current_price_formatted:
                          <span style={{ color: '#b8ad14' }}> &#32;$5,199.00</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;full_link:
                          <span style={{ color: '#b8ad14' }}> &#32;https://www...</span>
                          ,
                          <br />
                          &#160;&#160;&#160;&#160;&#32;&rdquo;images&rdquo;:&#32;&#32;
                          [
                          <br />
                          &#160;&#160; &#160;&#160;&#160;&#160;

                          &#160;&#160;url:
                          {' '}
                          <span style={{ color: '#b8ad14' }}>String</span>

                          &#160;&#160;&#160;&#160;&#160;&#160;
                          <br />
                          &#160;&#160;&#160;&#160;&#160;]
                          <br />
                          &#160;&#160;&#160;&#160;&#125;
                          <br />
                          &#160;&#160;]
                          <br />
                          &#125;

                        </span>
                      </code>
                    </pre>
                  </div>
                  <br />
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />

                </div>
              </div>
            </RepositoriesInfo>
            <br />
            <br />
            {/*
            * Delete an Product
            */}
            <RepositoriesInfo>
              <div>
                <div>
                  <span style={{ color: '#fff', fontSize: 22 }}>Delete Offer</span>
                  <br />
                  <br />
                  <div>
                    <span style={{ color: '#aa0909' }}>DELETE</span>
                    <span style={{ color: '#fff' }}> /products/offers/:offer_id</span>
                    <br />
                    <br />
                    <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                    <br />
                  </div>
                </div>

                <div>
                  <br />
                  <br />

                  <span style={{ color: '#fff' }}>
                    HEADERS:

                    <br />
                    <br />
                    &#160;&#32;Content-Type: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>application/json</span>
                    ,
                    <br />
                    <br />
                    &#160;&#32;Authorization: &#160;&#32;
                    <span style={{ color: '#b8ad14' }}>
                      Bearer
                      {' '}
                      <span style={{ color: '#197ecb' }}>TOKEN</span>
                    </span>
                    ,
                    <br />
                  </span>
                </div>

                <div>
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>Response</span>
                  <br />
                  <br />
                  <span style={{ color: '#fff' }}>Success: </span>
                  <span style={{ color: '#116e07' }}>200</span>
                  <br />
                  <br />
                  <span style={{ color: '#c45' }}>Error: </span>
                  <span style={{ color: '#cf9011' }}>400</span>
                  <pre>
                    <br />
                    <br />
                    <span style={{ color: '#e0cece', fontSize: 15 }}>
                      RESPONSE
                      <br />
                      <br />
                    </span>
                    <code>
                      <span style={{ color: '#fff' }}>
                        &#123;
                        <br />
                        &#160;&#32;
                        <span style={{ color: '#fff' }}>
                          error:

                        </span>
                        {' '}
                        <span style={{ color: '#b8ad14' }}>
                          &rdquo;
                          error message
                          &rdquo;

                        </span>
                        ,
                        <br />
                        &#125;

                      </span>
                    </code>
                  </pre>
                  <br />
                  <div style={{ borderBottom: '1px solid rgba(255,255,255,.3)' }} />

                </div>
              </div>
            </RepositoriesInfo>
            <br />
            <br />
          </ListDocumentation>
        </Page>
      </Container>
    </>
  );
};

export default Documentation;
