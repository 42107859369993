import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import Enrollment from '../pages/EnrollmentData';
import Documentation from '../pages/Documentation';
import Store from '../pages/Stores';
import Product from '../pages/Products';
import Login from '../pages/Login';
import Settings from '../pages/SettingsPage';

const Routes: React.FC = () => {
  const { signed } = useAuth();

  return (
    <>

      {
            signed ? (
              <Switch>
                <Route path="/login" exact component={Store} />
                <Route path="/" exact component={Store} />
                <Route path="/doc" component={Documentation} />
                <Route path="/stores" component={Store} />
                <Route path="/products" component={Product} />
                <Route path="/settings" component={Settings} />

              </Switch>
            ) : (
              <Switch>
                <Route path="/signup" component={Enrollment} />
                <Route path="/" component={Login} />
              </Switch>
            )

      }
    </>
  );
};

export default Routes;
