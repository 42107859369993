import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/analytics';

import logoLogin from '../../assets/logo1.svg';
import { useAuth } from '../../hooks/auth';
import {
  Container, Img, Painel, Button,
} from './styles';

// firebase.initializeApp(config);
firebase.auth().useDeviceLanguage();
declare const window: any;
const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { login } = useAuth();

  // const handleConfirmCode = () => {
  //   window.confirmationResult
  //     .confirm(code)
  //     .then((result: any) => {
  //       // User signed in successfully.
  //       const { user } = result;
  //       console.log('resultadop da confirmacao aqui', user);
  //       loginWithPhone(phone);
  //       // ...
  //     })
  //     .catch((error: any) => {
  //       console.log('error da confirmacao aqui:', error);
  //       // User couldn't sign in (bad verification code?)
  //       // ...
  //     });
  // };
  // const tel = document.getElementById('tel') as HTMLInputElement;
  // const mascaraTelefone = (valor: any) => {
  //   let x = valor.replace(/\D/g, '');
  //   x = x.replace(/^(\d{4})(\d)/g, '+($1) $2');
  //   x = x.replace(/(\d{5})(\d{4})$/, '$1-$2');
  //   tel.value = x;
  // };
  // tel?.addEventListener('keypress', (e: any) => mascaraTelefone(e.target?.value),
  // );
  // tel?.addEventListener('change', (e: any) => mascaraTelefone(e.target?.value));

  return (
    <Container>
      <Painel>
        <Img src={logoLogin} />
        <>
          <div>
            <label>Email: </label>
            <input
              name="email"
              id="password"
              placeholder="Email:"
              onChange={(event: any) => setEmail(event.target.value)}
              value={email}
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              name="password"
              id="password"
              placeholder="Password:"
              onChange={(event: any) => setPassword(event.target.value)}
              value={password}
            />
          </div>
          <Button onClick={() => login(email, password)}>Login</Button>
        </>
        <div style={{
          marginTop: 100,
        }}
        >
          <a href="/signup">Create an account</a>
        </div>
      </Painel>

    </Container>
  );
};

export default Login;
