import styled from 'styled-components';

export const Container = styled.div``;

export const MenuSidebar = styled.div`
  display: block;
  padding: 0 20px;
  a {
    color: #ffffff;
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    svg {
      margin-right: 16px;
    }
    &:hover {
      background-color: var(--primary600);
    }
  }
`;

export const Header = styled.div`
  padding: 24px;
  .logo-sidebar {
    display: block;
    max-height: 64px;
    margin-bottom: 24px;
  }
  .box-loja {
    background: #1a254f;
    border-radius: 8px;
    padding: 16px 24px;
    h3 {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #f0f1f5;
    }
    small {
      font-size: 12px;
      line-height: 15px;
      color: #7a82a2;
    }
  }
  @media (max-width: 950px) {
  }
  @media (max-width: 1121px) {
  }
`;
