import React, { useState, useEffect, useRef } from 'react';

import { useCoord } from '../../hooks/coordinates';

declare const window: any;
let autoComplete: any;

function SearchLocationInput(name: any, placeholder: any) {
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);
  const [coord1, setCoord1] = useState<any>();

  async function handlePlaceSelect(updateQuery: any) {
    const addressObject = autoComplete.getPlace();
    const query1 = addressObject.formatted_address;
    updateQuery(query1);
    setCoord1(addressObject);
    // console.log('coord input aqui:', addressObject?.geometry?.location.lng())
  }
  const { setCoord } = useCoord();
  useEffect(() => {
    if (coord1 !== undefined) {
      setCoord(coord1);
    }
  }, [coord1, setCoord]);

  useEffect(() => {
    const loadScript = (url: any) => {
      function handleScriptLoad(updateQuery: any, autoCompleteRef1: any) {
        autoComplete = new window.google.maps.places.Autocomplete(
          autoCompleteRef1.current,
          { types: ['geocode'], componentRestrictions: { country: 'br' } },
        );
        autoComplete.setFields([
          'address_components',
          'formatted_address',
          'geometry',
        ]);
        autoComplete.addListener('place_changed', () =>
          handlePlaceSelect(updateQuery),
        );
      }
      const script: any = document.createElement('script');
      script.type = 'text/javascript';

      if (script.readyState) {
        script.onreadystatechange = function () {
          if (
            script.readyState === 'loaded' ||
            script.readyState === 'complete'
          ) {
            script.onreadystatechange = null;
            const cllbk = () => handleScriptLoad(setQuery, autoCompleteRef);
            cllbk();
          }
        };
      } else {
        script.onload = () => handleScriptLoad(setQuery, autoCompleteRef);
      }

      script.src = url;
      document.getElementsByTagName('head')[0].appendChild(script);
    };
    loadScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAsGIIUgSiWProiAvVqIAwUmBfsJ5A9cxk&libraries=places',
    );
  }, []);

  return (
    <input
      name={name}
      placeholder={
        placeholder.typeOf === String ? placeholder : 'Pesquise seu Endereco'
      }
      ref={autoCompleteRef}
      onChange={(event: any) => setQuery(event.target.value)}
      value={query}
      className="form-control"
    />
  );
}

export default SearchLocationInput;
