import styled from 'styled-components';

interface Props {
  isDragActive?: any;
  dragActive?: any;
  dragReject?: any;
  isDragReject?: any;
  img?: any;
}
interface Type {
  type?: 'default' | 'error' | 'success';
}

export const Container = styled.div`
  display: flex;
  .section-form {
    margin-bottom: 24px;
  }
  .card-section {
    margin-bottom: 24px;
  }
`;
export const Page = styled.div`
  width: 100%;
`;
export const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: var(--primary500);
  margin-bottom: 24px;
`;

export const AddOutlet = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 16px rgba(18, 38, 63, 0.03);
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
  .title-inner {
    display: block;
    margin-bottom: 16px;
    color: var(--primary500);
  }
`;

export const MapStyle = styled.div`
  margin: 2px;
`;

export const ListOutlets = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 16px rgba(18, 38, 63, 0.03);
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
  .panel-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .panel-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 1.2;
      color: var(--primary500);
    }
    @media (max-width: 800px) {
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      .panel-title {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
`;
export const RepositoriesInfo = styled.div`
  table {
    width: 100%;
    border: solid 1px var(--primary100);
    border-collapse: collapse;
    border-spacing: 0;
    thead {
      tr {
        th {
          background-color: #fff;
          border: solid 1px var(--primary100);
          color: var(--primary500);
          padding: 12px;
          text-align: left;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
    tbody {
      tr {
        td {
          border: solid 1px var(--primary100);
          color: var(--primary500);
          padding: 12px;
          font-size: 13px;
          line-height: 16px;
          font-weight: normal;
        }
      }
    }
  }
`;

export const RightComponent = styled.div`
  display: flex;
  position: relative;
  width: 300px;
  svg {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    stroke: var(--primary300);
  }
`;

export const PaginateBar = styled.div``;

export const ButtonOption = styled.button`
  margin-left: 27%;
  border: none;
  background-color: #fff;
  & + button {
    margin-left: 5%;
  }
`;
export const DropContainer = styled.div<Props>`
  border: 1px dashed #000;
  border-radius: 50px;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  margin-top: 1%;
  max-width: 17%;
  cursor: pointer;
  transition: height 0.2s ease;

  ${(props) => props.isDragActive && props.dragActive};
  ${(props) => props.isDragReject && props.dragReject};
`;
const messageResult = {
  default: '#000',
  error: '#e57878',
  success: '#78e5d5',
};
export const UploadMessage = styled.p<Type>`
  display: flex;
  color: ${(props) => messageResult[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: 27px;
`;
export const ButtonOut = styled.button`
  border: none;
  background-color: transparent;
`;
export const AddEmployee = styled.div`
  position: absolute;
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  margin: 9%;

  border-radius: 12px;
  background-color: #c1d8d6;
  padding: 3%;
  input {
    border-radius: 7px;
    border: 2px solid #f0f0f0;
    width: 70%;
    margin-left: 10%;
    height: 27px;
    padding-left: 30%;
    margin-bottom: 0;
  }
  div {
    display: flex;
    margin-left: 4px;
  }
  header {
    position: fixed;
    bottom: 24%;
    left: 21%;
  }
  label {
    margin: 1%;
  }
  form {
    display: flex;
    width: 100%;
    div {
      display: block;
      input {
        border-radius: 7px;
        border: 2px solid #f0f0f0;
        padding-left: 10%;
        width: 100%;
        margin: 2%;
      }
    }
  }
  button {
    margin-left: 45%;
    width: 20%;
  }
`;
export const ButtonAddEmployee = styled.button`
  border: none;
  background-color: #fff;
  margin-right: 2%;
`;

export const Img = styled.img`
  max-width: 160%;
  max-height: 150%;
  border-radius: 50%;
  margin: 17%;
  margin-left: 100%;
`;
export const Emloyees = styled.div`
  padding: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 9px;
`;
