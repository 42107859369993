import styled from 'styled-components';

interface Props {
  isDragActive?: any;
  dragActive?: any;
  dragReject?: any;
  isDragReject?: any;
  img?: any;
}
interface Type {
  type?: 'default' | 'error' | 'success';
}

export const Container = styled.div``;
export const Page = styled.div``;
export const Title = styled.h3``;

export const EditStore = styled.div``;

export const MapStyle = styled.div``;

export const Logo = styled.img`
  border-radius: 32px;
  width: 80%;
  @media (max-width: 800px) {
    width: 64px;
  }
`;

export const DropContainer = styled.div<Props>`
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-color: #f8f8fa;
  border: 1px solid #f0f1f5;
  box-sizing: border-box;
  border-radius: 8px;
  ${(props) => props.isDragActive && props.dragActive};
  ${(props) => props.isDragReject && props.dragReject};
  text-align: center;
  color: var(--primary500);
  cursor: pointer;
  &:hover {
    background-color: #f0f1f5;
  }
`;
const messageResult = {
  default: '#212E63',
  error: '#FC4F4F',
  success: '#14C87C',
};
export const UploadMessage = styled.p<Type>`
  display: flex;
  color: ${(props) => messageResult[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: 32px;
`;
