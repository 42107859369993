import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { AiOutlineClose } from 'react-icons/ai';

import Button from '../../components/Button';
import Sidebar from '../../components/sidebar';
import Topbar from '../../components/Topbar';

import {
  Container,
  Page,
  Title,
  AddOutlet,
  DropContainer,
  UploadMessage,
  AddEmployee,
  ButtonOut,
} from './styles';

interface IAddress {
  street: string;
  street_number: string;
  zipcode: string;
  country: string;
  state: string;
  city: string;
  neighborhood: string;
  complementary: string;
}

const buttonUpdate = false;

declare const window: any;

export const Stores: React.FC = () => {
  const [load, setLoad] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [sku, setSku] = useState<string>('');
  const [api_string, setApi_string] = useState<string>();
  const [filesPreview, setFilesPreview] = useState<any>();
  const [employee_name, setEmployee_name] = useState<string>();
  const [employee_phone, setEmployee_phone] = useState<string>('+55');
  const [employee_email, setEmployee_email] = useState<string>();
  const [salesValue, setSalesValue] = useState(false);

  const GetReservationValues: React.FC = () => {
    const [data, setData] = useState<any>();
    // let qt = 0;
    // function set(quantity: number) {
    //   qt = quantity;
    // }
    useEffect(() => {
      const func = async () => {
        //
        setData(['']);
        setLoad(true);
      };
      func();
    }, []);
    return (
      <React.StrictMode>
        <AddEmployee>
          <header>
            <ButtonOut onClick={() => setSalesValue(false)}>
              <AiOutlineClose size={25} color="red" />
              {' '}
              Fechar
            </ButtonOut>
          </header>
          <div>
            <form>
              <label>
                <h3>Dados das Reservas desse outlet:</h3>
              </label>
              <div>
                <div>
                  <label>Numeros de Reservas Autorizadas</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.authorized}
                    disabled
                  />
                  <label>Valor Total das Reservas Autorizadas</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.authorized_value}
                    disabled
                  />
                </div>
                <div>
                  <label>Numeros de Reservas Pendentes</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.pending}
                    disabled
                  />
                  <label>Valor Total das Reservas Pendentes</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.pending_value}
                    disabled
                  />
                </div>
                <div>
                  <label>Numeros de Reservas Expiradas</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.expired}
                    disabled
                  />
                  <label>Valor Total das Reservas Expiradas</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.expired_value}
                    disabled
                  />
                </div>
                <div>
                  <label>Numeros de Reservas Negadas</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.denied}
                    disabled
                  />
                  <label>Valor Total das Reservas Negadas</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.denied_value}
                    disabled
                  />
                </div>
                <div>
                  <label>Numeros de Reservas Resgatadas</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.rescued}
                    disabled
                  />
                  <label>Valor Total das Reservas Resgatadas</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.rescued_value}
                    disabled
                  />
                </div>
                <div>
                  <label>Numeros de Reservas Canceladas</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.canceled}
                    disabled
                  />
                  <label>Valor Total das Reservas Canceladas</label>
                  <input
                    name="employeename"
                    placeholder="Nome"
                    value={data?.canceled_value}
                    disabled
                  />
                </div>
              </div>
            </form>
          </div>
        </AddEmployee>
      </React.StrictMode>
    );
  };

  const handleEmployeeSubmit = async () => {
    //
  };

  const handleSubmit = async () => {
    //
  };

  const handleSubmitUpdate = async (): Promise<void> => {
    //
  };

  const renderDragMessage = (isDragActive: any, isDragReject: any) => {
    if (!isDragActive) {
      return (
        <UploadMessage>Arraste ou click para adicionar a foto</UploadMessage>
      );
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado!</UploadMessage>;
    }
    return <UploadMessage type="success">Solte aqui ... </UploadMessage>;
  };
  const handleUpload = async (files: any) => {
    const preview = URL.createObjectURL(files?.[0]);
    setFilesPreview(preview);
  };
  const tel = document.getElementById('phon') as HTMLInputElement;
  const mascaraTelefone = (valor: any) => {
    let x = valor.replace(/\D/g, '');
    x = x.replace(/^(\d{4})(\d)/g, '+($1) $2');
    x = x.replace(/(\d{5})(\d{4})$/, '$1-$2');
    tel.value = x;
  };
  tel?.addEventListener('keypress', (e: any) => mascaraTelefone(e.target?.value),
  );
  tel?.addEventListener('change', (e: any) => mascaraTelefone(e.target?.value));

  return (
    <>
      <Container className="wrapper">
        <Sidebar />
        <Topbar />
        <Page className="content">
          <Title>Loja</Title>

          {edit && (
            <AddEmployee>
              <ButtonOut onClick={() => setEdit(false)}>
                <AiOutlineClose size={25} color="red" />
                {' '}
                Fechar
              </ButtonOut>

              <div>
                <label>Adicionar Funcionario a esse outlet:</label>
              </div>

              <div>
                <Dropzone accept="image/*" onDropAccepted={handleUpload}>
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                  }) => (
                    <DropContainer
                      {...getRootProps()}
                      isDragActive={isDragActive} // aceitar img
                      isDragReject={isDragReject}
                      img={filesPreview}
                    >
                      <input {...getInputProps()} />
                      {renderDragMessage(isDragActive, isDragReject)}
                    </DropContainer>
                  )}
                </Dropzone>

                <div>
                  <form>
                    <div>
                      <div className="form-group">
                        <input
                          name="employeename"
                          placeholder="Nome"
                          onChange={(event: any) => setEmployee_name(event.target.value)}
                          value={employee_name}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="employeephone"
                          id="phon"
                          placeholder="Telefone"
                          onChange={(event: any) => setEmployee_phone(event.target.value)}
                          value={employee_phone}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="employeeemail"
                          placeholder="Email"
                          onChange={(event: any) => setEmployee_email(event.target.value)}
                          value={employee_email}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <Button
                onClick={() => handleEmployeeSubmit()}
                className="btn btn-success"
              >
                Adicionar
              </Button>
            </AddEmployee>
          )}
          <AddOutlet>
            {salesValue && <GetReservationValues />}

            <div className="row card-section">
              <div className="col-xs-12">
                <h4 className="title-inner">Info:</h4>

                <form onSubmit={handleSubmit}>
                  <div className="row section-form">
                    <div className="col-xs-12 col-md-6">
                      <div className="form-group" />

                    </div>
                    <div className="col-xs-12 col-md-6">
                      <div className="form-group">
                        <input
                          name="email"
                          placeholder="Email: "
                          className="form-control"
                          onChange={(event: any) => setEmail(event.target.value)}
                          value={email}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="sku"
                          placeholder="SKU"
                          className="form-control"
                          onChange={(event: any) => setSku(event.target.value)}
                          value={sku}
                        />
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>

            <div className="row card-section">
              <div className="col-xs-12">
                <h4 className="title-inner">API Key</h4>

                <div className="form-group">
                  <input
                    name="api_string"
                    placeholder="generate new key"
                    onChange={(event: any) => setApi_string(event.target.value)}
                    value={api_string}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            {load && <Button className="btn btn-primary">...</Button>}
            {buttonUpdate && (
              <Button onClick={handleSubmitUpdate} className="btn btn-success">
                Generate
              </Button>
            )}
          </AddOutlet>

        </Page>
      </Container>
    </>
  );
};

export default Stores;
