import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';

import {
  FiCornerUpLeft,
} from 'react-icons/fi';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import Button from '../../components/Button';
import logoLogin from '../../assets/logoLogin.png';
import SearchLocationInput from '../../components/SearchInput';
import { useCoord } from '../../hooks/coordinates';
import { uploadImageToStorage } from '../../Firebase/uploadImage';
import {
  Container,
  Page,
  Title,
  EditStore,
  DropContainer,
  UploadMessage,
  Btn1,
  Button2,
  SuccessContainer,
  SuccessText,
} from './styles';

declare const window: any;

interface IBody {
  name: string
  corporateName?: string
  cnpj?: string
  email: string
  phone?: string
  street?: string
  number?: string
  zipcode?: string
  neighborhood?: string
  city?: string
  state?: string
  active: boolean
  password: string
  slug?: string
  imageUrl?: string
}

export const EnrollmentData: React.FC = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [slug, setSlug] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [filesPreview, setFilesPreview] = useState<any>();
  const [corporateName, setCorporate_name] = useState<string>('');
  const [cnpj, setCnpj] = useState<string>('');
  const [file, setFile] = useState<any>('');
  const [streetNumber, setStreetNumber] = useState<any>('');
  const [route, setRoute] = useState<any>('');
  const [neighborhood, setNeighborhood] = useState<any>('');
  const [city, setCity] = useState<string>('');
  const [complementary, setComplementary] = useState<any>();
  const [state, setState] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [postalCode, setPostalCode] = useState<any>('');

  // const phone = localStorage.getItem('@off-let:signed');

  const { coordenates } = useCoord();

  const { logout } = useAuth();

  const renderDragMessage = (isDragActive: any, isDragReject: any) => {
    if (!isDragActive) {
      return (
        <UploadMessage type="default">
          Drag or click to add logo
        </UploadMessage>
      );
    }
    if (isDragReject) {
      return <UploadMessage type="error">unsupported file!</UploadMessage>;
    }
    return <UploadMessage type="success">on here ... </UploadMessage>;
  };

  const addressComplete = (addss: any) => {
    addss?.map((addrss: any) => {
      switch (addrss.types[0]) {
        case 'street_number':
          setStreetNumber(addrss.long_name);
          break;
        case 'route':
          setRoute(addrss.long_name);
          break;
        case 'political':
          setNeighborhood(addrss.long_name);
          break;
        case 'sublocality_level_1':
          setNeighborhood(addrss.long_name);
          break;
        case 'administrative_area_level_2':
          setCity(addrss.long_name);
          break;
        case 'administrative_area_level_1':
          setState(addrss.long_name);
          break;
        case 'country':
          setCountry(addrss.long_name);
          break;
        case 'postal_code':
          setPostalCode(addrss.long_name);
          break;

        default:
          break;
      }
    });
  };

  useEffect(() => {
    async function anyNameFunction2() {
      if (coordenates) {
        addressComplete(coordenates.address_components);
      }
    }
    anyNameFunction2();
  }, [coordenates]);

  const handleUpload = async (files: any) => {
    setFile(files);
    const preview = URL.createObjectURL(files?.[0]);
    setFilesPreview(preview);
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      let avatar: any;
      if (file) {
        const avatarT = await uploadImageToStorage(file, cnpj);
        avatar = avatarT;
      }
      if (confirmPassword !== password) {
        alert('Password unmatch');
        return;
      }
      const data: IBody = {
        corporateName,
        email,
        password,
        phone,
        slug,
        name,
        cnpj,
        imageUrl: avatar,
        active: false,
        street: route,
        number: streetNumber,
        zipcode: postalCode,
        state,
        city,
        neighborhood,
      };
      console.log(data);
      const response = await api.post('/company', data);
      console.log({ response });
      if (response.data.email === email) {
        setSlug('');
        setSuccess(true);
        setPhone('');
      }
    } catch (error) {
      //
    }
  };

  return (
    <>
      {!success && (
      <Container>
        <Page>
          <Btn1 type="button" onClick={() => logout()}>
            <FiCornerUpLeft size={20} color="white" />
            {'  '}
            <h2>Voltar</h2>
          </Btn1>
          <div className="row">
            <div className="col-xs-12 col-md-8 col-md-offset-2">
              <div className="row">
                <div className="col-xs-12 col-md-3 logo-header">
                  <Button2 type="button" onClick={() => {}}>
                    <img src={logoLogin} alt="offlet" />
                  </Button2>
                </div>
                <div className="col-xs-12 col-md-9">
                  <Title>
                    This is your first login!
                    <span>
                      Please register your company information
                    </span>
                  </Title>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-8 col-md-offset-2">
              <EditStore className="panel">
                <div className="row">
                  <div className="col-xs-12 col-md-3">
                    <div className="form-group">
                      <label>Logo:</label>
                      <Dropzone accept="image/*" onDropAccepted={handleUpload}>
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragReject,
                        }) => (
                          <DropContainer
                            {...getRootProps()}
                            isDragActive={isDragActive} // aceitar img
                            isDragReject={isDragReject}
                            img={filesPreview}
                          >
                            <input {...getInputProps()} />
                            {renderDragMessage(isDragActive, isDragReject)}
                          </DropContainer>
                        )}
                      </Dropzone>
                    </div>
                  </div>

                  <div className="col-xs-12 col-md-9">
                    <form>
                      <div className="row">
                        <div className="form-group col-xs-12">
                          <input
                            name="name"
                            placeholder="Name"
                            onChange={(event: any) => setName(event.target.value)}
                            value={name}
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-xs-12 col-md-6">
                          <input
                            name="generic_name"
                            placeholder="Corporate Name"
                            onChange={(event: any) => setCorporate_name(event.target.value)}
                            value={corporateName}
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-xs-12 col-md-6">
                          <input
                            name="cnpj"
                            placeholder="CNPJ"
                            onChange={(event: any) => setCnpj(event.target.value)}
                            value={cnpj}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-xs-12">
                          <input
                            name="Email"
                            placeholder="Email"
                            onChange={(event: any) => setEmail(event.target.value)}
                            value={email}
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-xs-12 col-md-6">
                          <input
                            name="password"
                            placeholder="Password"
                            onChange={(event: any) => setPassword(event.target.value)}
                            value={password}
                            type="password"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-xs-12 col-md-6">
                          <input
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm the Password"
                            onChange={(event: any) => setConfirmPassword(event.target.value)}
                            value={confirmPassword}
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/* ENDEREÇO */}

                      <div className="row">
                        <div className="col-xs-12">
                          <div className="divider-form-section" />
                        </div>

                        <div className="form-group col-xs-12 col-md-12">
                          <SearchLocationInput name="any" />
                        </div>

                        <div className="form-group col-xs-12 col-md-6">
                          <input
                            name="zipcode"
                            placeholder={postalCode}
                            disabled
                            className="form-control"
                          />
                        </div>

                        <div className="form-group col-xs-12 col-md-6">
                          <input
                            name="street"
                            placeholder={route}
                            disabled
                            className="form-control"
                          />
                        </div>

                        <div className="form-group col-xs-12">
                          <input
                            name="street_number"
                            placeholder={streetNumber}
                            disabled
                            className="form-control"
                          />
                        </div>

                        <div className="form-group col-xs-12">
                          <input
                            name="complementary"
                            placeholder="complement"
                            onChange={(event: any) => setComplementary(event.target.value)}
                            value={complementary}
                            className="form-control"
                          />
                        </div>

                        <div className="form-group col-xs-12">
                          <input
                            name="neighborhood"
                            placeholder={neighborhood}
                            disabled
                            className="form-control"
                          />
                        </div>

                        <div className="form-group col-xs-12">
                          <input
                            name="city"
                            placeholder={city}
                            disabled
                            className="form-control"
                          />
                        </div>

                        <div className="form-group col-xs-12">
                          <input
                            name="state"
                            placeholder={state}
                            disabled
                            className="form-control"
                          />
                        </div>

                        <div className="form-group col-xs-12">
                          <input
                            name="country"
                            placeholder={country}
                            disabled
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12">
                          <Button
                            onClick={handleSubmit}
                            className="btn btn-success btn-block"
                          >
                            Create an account

                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </EditStore>
            </div>
          </div>
        </Page>
      </Container>
      )}
      {
        success && (
          <SuccessContainer>
            <div>
              <SuccessText>
                We send an email to
                {' '}
                {email}
                , with a confirmation link
              </SuccessText>
            </div>
            <Button
              onClick={logout}
              style={{
                backgroundColor: '#c45',
                marginLeft: '5%',
                width: '90%',
                color: '#fff',
              }}
            >

              Sign In

            </Button>
          </SuccessContainer>
        )
      }
    </>
  );
};

export default EnrollmentData;
