import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/analytics';

const config = {
  apiKey: 'AIzaSyAsGIIUgSiWProiAvVqIAwUmBfsJ5A9cxk',
  authDomain: 'off-let.firebaseapp.com',
  databaseURL: 'https://off-let.firebaseio.com',
  projectId: 'off-let',
  storageBucket: 'off-let.appspot.com',
  messagingSenderId: '780373306450',
  appId: '1:780373306450:web:c81688cb4e99dcb19c038b',
  measurementId: 'G-3D9SRBJV2E',
};
firebase.initializeApp(config);
firebase.analytics();
firebase.auth().useDeviceLanguage();

const auth = firebase.auth();
const authSocialMedia = firebase.auth;
const firestore = firebase.firestore();
const storage = firebase.storage();
const captcha = firebase.auth;
// captcha()
// window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
//   size: 'invisible',
//   callback(response) {
//     // reCAPTCHA solved, allow signInWithPhoneNumber.
//     onSignInSubmit();
//   },
// });

export {
  auth, firestore, authSocialMedia, storage, captcha, config,
};
