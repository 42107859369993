import { createGlobalStyle } from 'styled-components';
import './grid.css';

export default createGlobalStyle` @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
  --primary100: #F0F1F5;
  --primary200: #A6ACC1;
  --primary300: #7A82A2;
  --primary400: #4E5882;
  --primary500: #212E63;
  --primary600: #1B254F;
  --primary700: #141C3C;
  --primary800: #0D1328;
  --primary900: #070914;
  --accent100: #FAD8DB;
  --accent200: #F6B1B8;
  --accent300: #F18A94;
  --accent400: #ED6371;
  --accent500: #E83C4D;
  --accent600: #BA303E;
  --accent700: #8B242E;
  --accent800: #5D181F;
  --accent900: #2E0C0F;
  --feedbackSuccess500: #14C87C;
  --feedbackSuccess600: #10A063;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

a {
  text-decoration: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  background: #f3f3f7;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

.root {
  height: 100%;
  width: 100%;
}

.wrapper {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 60px 1fr;
  grid-template-areas:
    "sidebar topbar"
    "sidebar content";
  height: 100vh;
  width: 100vw;

  @media(max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 64px 1fr;
    grid-template-areas:
      "topbar"
      "content";
  }

  .topbar {
    grid-area: topbar;
    height: 60px;
    background-color: #ffffff;
    box-shadow: 0px 2px 16px rgba(33, 46, 99, 0.06);

    @media(max-width: 800px) {
      background-color: var(--primary600);
    }
  }

  .sidebar {
    grid-area: sidebar;
    display: block;
    background-color: #1e2d67;
    color: #a8a8b3;
    position: sticky;
    top: 0;
    left: 0;

    @media(max-width: 800px) {
      position: fixed;
      left: -100%;
      height: 100%;
    }

    button {
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: #2b3040;
      border: none;
      color: #fff;
      transition: color 0.2s;

      &:hover {
        color: #666;
      }
    }
  }


  .content {
    grid-area: content;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 24px 24px 0;
  }
}

.page-heading {
  @media(max-width:800px) {
    margin-bottom: 16px;
  }

  .page-title {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: var(--primary500);
    margin-bottom: 24px;

    @media(max-width:800px) {
      margin-bottom: 8px;
    }
  }
}

button {
  cursor: pointer;
  transition: all .2s linear;
}

.form-group {
  margin-bottom: 16px;

  label {
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #212E63;
    margin-bottom: 4px;
  }
}

.form-control {
  font-size: 14px;
  border: 1px solid var(--primary100);
  height: 36px;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
}

.divider-form-section{
  display: block;
  width: 100%;
  margin: 0 0 16px;
  border-top: 1px dashed #F0F1F5;
}


.btn-block {
  width: 100%;
}

.panel {
  background: #ffffff;
  box-shadow: 0px 3px 16px rgba(18, 38, 63, 0.03);
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  table {
    thead {
      tr {
        th {
          white-space: nowrap;
        }
      }
    }
    tbody{
      tr{
        td{}
      }
    }
  }
}

.table {
  width: 100%;
  border: solid 1px var(--primary100);
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    tr {
      th {
        background-color: #fff;
        border: solid 1px var(--primary100);
        color: var(--primary500);
        padding: 12px;
        text-align: left;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  tbody {
    tr {
      td {
        border: solid 1px var(--primary100);
        color: var(--primary500);
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
        font-weight: normal;
        @media(max-width:800px){
          &:before{
            content: attr(data-title) ": ";
          }
        }
      }
    }
  }
}

.pagination-table {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media(max-width: 800px) {
    flex-direction: column;
  }

  small {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: var(--primary300);

    @media(max-width: 800px) {
      margin-bottom: 16px;
    }
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      height: 26px;
      width: auto;
      padding: 4px 8px;
      background: #ffffff;
      border: 1px solid var(--primary100);
      border-right: 0;
      border-radius: 0;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      color: var(--primary400);

      &:hover {
        background-color: var(--primary100);
      }

      &:last-child {
        border-right: 1px solid var(--primary100);
      }
    }
  }
}

`;
