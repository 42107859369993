import styled from 'styled-components';
import { darken } from 'polished';

export const ButtonComponent = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.5;
  border-radius: 6px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    background: ${darken(0.2, '#FFCD64')};
  }
  &.btn-accent {
    color: #fff;
    background-color: var(--accent500);
    border-color: var(--accent500);
    &:hover {
      background-color: var(--accent600);
      border-color: var(--accent600);
    }
  }
  &.btn-primary {
    color: #fff;
    background-color: var(--primary500);
    border-color: var(--primary500);
    &:hover {
      background-color: var(--primary600);
      border-color: var(--primary600);
    }
  }
  &.btn-success {
    color: #fff;
    background-color: var(--feedbackSuccess500);
    border-color: var(--feedbackSuccess500);
    &:hover {
      background-color: var(--feedbackSuccess600);
      border-color: var(--feedbackSuccess600);
    }
  }
`;
