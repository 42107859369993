import styled from 'styled-components';

interface Props {
  status:
    | 'pending'
    | 'authorized'
    | 'denied'
    | 'rescued'
    | 'canceled'
    | 'expired';
}

export const Container = styled.div``;
export const Page = styled.div``;
export const Title = styled.h3``;
export const ListDocumentation = styled.div``;
export const RepositoriesInfo = styled.div`
  background-color: #222121;
  padding: 5%;
`;
export const RightComponent = styled.div`
  display: flex;
  align-items: center;
  input {
  }
  svg {
    position: relative;
    margin-left: -32px;
  }
`;
export const ButtonOption = styled.button`
  margin-left: 27%;
  margin-top: 7%;
  border: none;
  background-color: #fff;
  & + button {
    margin-left: 7%;
  }
`;

export const PaginateBar = styled.div``;

const colorResult = {
  authorized: '#eafbf1',
  pending: '#ecedfd',
  denied: '#fce4e6',
  rescued: '#eafbf1',
  canceled: '#fce4e6',
  expired: '#fce4e6',
};
const colorText = {
  authorized: '#14c87c',
  pending: '#6a6eec',
  denied: '#e83c4d',
  rescued: '#14c87c',
  canceled: '#e57878',
  expired: '#e83c4d',
};

export const ButtonStatus = styled.button<Props>`
  margin-left: 2%;
  margin-right: 3%;
  border: none;
  border-radius: 4px;
  font-size: 90%;
  padding: 4%;
  max-width: 100%;
  min-width: 100%;
  background-color: ${(props) => colorResult[props.status]};
  color: ${(props) => colorText[props.status]};
  
`;
