import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;
export const Page = styled.div`
  width: 100%;
`;
export const Title = styled.h3``;

export const ListProduct = styled.div`
  background-color: #f3f3f7;
`;
export const Btn = styled.button`
  background-color: #fff;
  border: none;
  margin-right: 3%;
`;
export const Btn1 = styled.button`
  margin: 3%;
  border: none;
  background-color: #f3f3f7;
`;
export const Btn2 = styled.button`
  margin-left: 27%;
  border: none;
  background-color: #f3f3f7;
  & + button {
    margin-left: 5%;
  }
`;
export const RepositoriesInfo = styled.div``;

export const Td = styled.td`
  background-color: #f3f3f7;
  font-size: 12px;
`;
export const Td0 = styled.td`
  padding: 1px;
  height: 32px;
  margin: 0;
  max-width: none;
  padding: 1px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  font-size: 12px;
`;

export const RightComponent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  input {
  }
  svg {
    position: relative;
    margin-left: -32px;
  }
`;
export const PaginateBar = styled.div``;
export const EditProduct = styled.div`
  justify-content: center;
  align-items: center;

  background-color: #fff;
  padding: 1%;
  input {
    border-radius: 7px;
    border: 2px solid #000;
    min-width: 60%;
    margin-left: 5%;
    height: 27px;
    padding-left: 5%;
    margin-bottom: 0;
  }
  div {
    display: flex;
    margin-left: 1px;
  }
  label {
    margin: 4%;
  }
  button {
    margin-left: 10%;
  }
  h4 {
    border: none;
    border-radius: 7px;
    padding: 1%;
    margin: 1%;
    background-color: #bdd3d3;
    width: 30%;
  }
`;
export const TitleVariation = styled.div`
  margin: 3%;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  border: none;
  border-radius: 12px;
  background-color: #b2dabf;
  color: #fcfcfc;
`;
export const ButtonVariationEdit = styled.button`
  border: none;
  background-color: transparent;
  margin-right: 0%;
`;
export const Variation = styled.div`
  background-color: #f4fcfc;
  padding: 3%;
  margin: 2%;
  h5 {
    margin-right: 1%;
    width: 100%;
  }
  button {
    width: 70%;
  }
`;
