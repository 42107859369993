import React from 'react';

import Sidebar from '../../components/sidebar';
import Topbar from '../../components/Topbar';
import {
  Container,
  Page,
} from './styles';

export const Products: React.FC = () => {
  return (
    <>
      <Container className="wrapper">
        <Sidebar />
        <Topbar />
        <Page className="content" />
      </Container>
    </>
  );
};

export default Products;
