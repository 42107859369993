import React from 'react';
import { AiOutlineLogout } from 'react-icons/ai';

import { Container } from './styles';
import { useAuth } from '../../hooks/auth';

import Logo from '../../assets/logoLogin.png';

const Topbar: React.FC = () => {
  const { logout } = useAuth();
  const logOut = () => {
    logout();
  };

  return (
    <Container className="topbar">
      <div className="menu-wrapper">
        <div className="hamburger-menu"></div>
      </div>
      <img src={Logo} alt="off-let" className="logo-topbar" />
      <button type="button" className="logout-button" onClick={() => logOut()}>
        <AiOutlineLogout size={19} />
        Sair
      </button>
    </Container>
  );
};

export default Topbar;
