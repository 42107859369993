import React, {
  createContext, useCallback, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';

interface ICoordContext {
    coordenates: any;
    setCoord(coordenates: any): void;
}

const CoordContext = createContext<ICoordContext>({} as ICoordContext);

const LocationProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ICoordContext>();

  const setCoord = useCallback(async (address) => {
    // console.log('aqui o hook address', address)
    setData(address);
  }, []);

  return (
    <CoordContext.Provider value={{ coordenates: data, setCoord }}>
      {children}
    </CoordContext.Provider>
  );
};

function useCoord(): ICoordContext {
  const context = useContext(CoordContext);

  if (!context) {
    throw new Error('useAuth must be used within an LocationProvider');
  }

  return context;
}
LocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export { LocationProvider, useCoord };
