import React from 'react';
import PropTypes from 'prop-types';

import { LocationProvider } from './coordinates';
import { AuthProvider } from './auth';

const AppProvider: React.FC = ({ children }) => (
  <LocationProvider>
    <AuthProvider>{children}</AuthProvider>
  </LocationProvider>
);
AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
