import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { AiFillSetting } from 'react-icons/ai';
import { FaStore } from 'react-icons/fa';
import { MdDeveloperMode } from 'react-icons/md';
import { RiShoppingBag3Line } from 'react-icons/ri';

import { Container, Header, MenuSidebar } from './styles';
import Logo from '../../assets/logoLogin.png';

const Dashboard: React.FC = () => {
  const [commercial_name, setCommercial_name] = useState('');
  const [id, setId] = useState();

  useEffect(() => {
    // const store = JSON.parse(localStorage.getItem('@off-let:store') || '');
    setCommercial_name('tcl');
    setId(1);
  }, []);

  return (
    <Container className="sidebar">
      <Header>
        <img src={Logo} alt="off-let" className="logo-sidebar" />
        <div className="box-loja">
          <h3>{commercial_name}</h3>
          <small>
            ID:
            {' '}
            {id}
          </small>
        </div>
      </Header>

      <MenuSidebar>
        <Link to="/stores">
          <FaStore size={20} />
          Company
        </Link>
        <Link to="/products">
          <RiShoppingBag3Line size={20} />
          Products
        </Link>
        <Link to="/doc">
          <MdDeveloperMode size={20} />
          Developers
        </Link>
        {/* <Link to="/settings">
          <AiFillSetting size={20} />
          Settings
        </Link> */}
      </MenuSidebar>
    </Container>
  );
};

export default Dashboard;
